import React from 'react';
import Chatbot from './ChatbotMain.js';
//import ChatBox from './Chatboxtest.js';

const App = () => {
  return (
    <div>
      <Chatbot />
    </div>
  );
};

// const App = () => {
//   return (
//     <div>
//       <ChatBox />
//     </div>
//   );
// };


export default App;

// import React from 'react';

// function App() {
//   return (
//     <div>
//       <h1>Hello, Firebase!</h1>
//       <p>This is a simple React app for testing Firebase deployment.</p>
//       <button onClick={() => alert('Button clicked!')}>Click Me</button>
//     </div>
//   );
// }

// export default App;